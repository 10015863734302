import React from 'react';
import UseCaseTemplate from '../../components/UseCaseTemplate'

import schiebetuer_00 from '../../images/schiebetuer_00_1600.webp'


const context = {
  title: 'Elektrische Schiebetür im Supermarkt',
  titleVariant1: 'Schiebetür im Supermarkt',
  titleVariant2: 'Elektrische Schiebetür',
  description: 'Öffnet leise, berührungslos und lässt sich in jede Umgebung intergrieren ',
  text: 'Eine automatische Schiebetüre besteht aus ein bis zwei sich öffnenden Türflügeln sowie einer Antriebs- und Sensorikeinheit. Sie kann beispielsweise in ein Rahmenelement (Festverglasung) oder in ein Mauerwerk eingefasst werden. Die Einbaumaße dieser Automatiktür bewegen sich meist in einer lichten Einbaubreite von 800 mm bis 3000 mm. \n\n' +

    'Die Automatiktüren ermöglichen es, Eingangssituationen barrierefrei, hygienisch und zeitgemäß zu gestalten. Sie lassen sich durch ihre leichte und elegante Bauweise in fast allen Neu- und Umbauten einfügen. Zudem können automatische Schiebetüren unter Beachtung weiterer Bestimmungen auch im Bereich eines Flucht- und Rettungswegs verbaut werden. Automatikschiebetüren sind daher die häufigste Lösung für gewerbliche und öffentliche Eingänge. \n\n' +

    'Nicht von der Stange -  jede Automatiktür ist eine individuell angepasste Lösung. Denn erst wenn das Produkt und die örtlichen Gegebenheiten ineinandergreifen kann eine langfristig zufriedenstellende Eingangssituation entstehen. Um diese nach Ihren Vorstellungen und Wünschen technisch und optisch perfekt zu gestalten, legen wir von Automatiktür24 größten Wert auf eine smarte Beratung und kompetente Umsetzung.',
  features: [
    //'autschr',
    'barrierefreiheit',
    //'brandschutz',
    //'breakOut',
    //'design',
    //'din18650',
    //'energieeinsparung',
    'modernisierung',
    'notStrom',
    'personenfrequenz',
    //'platzsparend',
    //'transparenz',
    //'einbruchhemmung',
    'zutrittskontrolle',
    'fluchtweg',
  ],
  legalRequirements: [
    'autschr',
    'din18650',
    //'dinEn170',
    //'eltvtr',
  ],
  carouselImages: [
    {
      original: schiebetuer_00,
      originalAlt: 'Automatische Schiebetür im Supermarkt'
    },
  ],
  configurator: true,
}

const Page = () => {

  return(
    <UseCaseTemplate pageContext={context} />
  )
}

export default Page;
